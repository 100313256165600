// @ts-nocheck

import styled from 'styled-components'
import {  useEffect,useCallback, useState } from 'react'
import PageSection from 'components/PageSection'
import { useWeb3React } from '@pancakeswap/wagmi'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { ChainId } from '@pancakeswap/sdk'
import { Flex } from '@pancakeswap/uikit'
import Hero from './components/Hero'
import { swapSectionData, earnSectionData, cakeSectionData } from './components/ChatSection/data'
import { cardSectionData } from './components/ProfileSection/data'
import { pnlSectionData } from './components/PnlCardsSection/data'
import { shownotesSectionData } from './components/ShowNotesSection/data'
import { nfttraderSectionData } from './components/NftTraderSection/data'
import MetricsSection from './components/MetricsSection'
import WinSection from './components/WinSection'
import ChatSection from './components/ChatSection'
import ProfileSection from './components/ProfileSection'
import PnlCardsSection from './components/PnlCardsSection'
import ShowNotesSection from './components/ShowNotesSection'
import NftTraderSection from './components/NftTraderSection'
import FarmsPoolsRow from './components/FarmsPoolsRow'
import Footer from './components/Footer'
import CakeDataRow from './components/CakeDataRow'
import { WedgeTopLeft, InnerWedgeWrapper, OuterWedgeWrapper, WedgeTopRight } from './components/WedgeSvgs'
import UserBanner from './components/UserBanner'
import MultipleBanner from './components/Banners/MultipleBanner'
import { useUser, useLogin, useLogout } from "@thirdweb-dev/react/solana";
import LoginDialog2 from './components/LoginDialog2'


const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`

const UserBannerWrapper = styled(Container)`
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 0px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { account } = useWeb3React()
  const { chainId } = useActiveChainId()
  const [isValid, setIsValid] = useState(false)
  const [starkPass, setStarkPass] = useState(false)

  const wallet = useWallet();
  console.log('here is the wallet in the home view')



  useEffect(() => {
    if (wallet.publicKey !== null) {
      setIsValid(true)
      console.log('as you can see the lesson is not coming together')
      console.log(wallet.publicKey.toBase58())
      setStarkPass(true)

    }
    if (wallet.publicKey === null) {
      setIsValid(false)

    }


  }, [wallet.publicKey])
















  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }

  const { t } = useTranslation()

  return (
    <>
      {isValid && (
        <Flex justifyContent="space-between" alignItems="center">
        <LoginDialog2/>
        </Flex>
      )}

      <PageMeta />
      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        background={
          theme.isDark
            ? 'radial-gradient(103.12% 50% at 50% 50%, #08060B 0%, #100E12 100%)'
            : 'linear-gradient(139.73deg, #08060B 0%, #100E12 100%)'
        }
        index={2}
        hasCurvedDivider={false}
      >
        {account && chainId === ChainId.BSC && (
          <UserBannerWrapper>
            <UserBanner />
          </UserBannerWrapper>
        )}
        <MultipleBanner />
        <Hero />
      </StyledHeroSection>
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        background={
          theme.isDark
            ? 'linear-gradient(180deg, #08060B 22%, #171618 100%)'
            : 'linear-gradient(180deg, #08060B 22%, #171618 100%)'
        }
        index={2}
        hasCurvedDivider={false}
      >
        <MetricsSection />
      </PageSection>
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        background={
          theme.isDark
            ? 'linear-gradient(180deg, #08060B 22%, #171618 100%)'
            : 'linear-gradient(180deg, #08060B 22%, #171618 100%)'
        }
        index={2}
        hasCurvedDivider={false}
      >
      <ChatSection {...cakeSectionData(t)} />
      </PageSection>



      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={
          theme.isDark
            ? 'linear-gradient(180deg, #171618 0%, #1E1E1E 100%)'
            : 'linear-gradient(180deg, #171618 0%, #1E1E1E 100%)'
        }
        index={2}
        hasCurvedDivider={false}
      >
        <WinSection />
      </PageSection>

    </>
  )
}

export default Home
