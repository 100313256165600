// @ts-nocheck
// @ts-nocheck

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ArrowForwardIcon, Button, Flex, Heading, Skeleton, Text, useModal,Modal, Box } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from '@pancakeswap/localization'
import { formatLocalisedCompactNumber } from 'utils/formatBalance'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { getTotalWon } from 'state/predictions/helpers'
import { useBNBBusdPrice, useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { multiplyPriceByAmount } from 'utils/prices'
import useSWR from 'swr'
import { SLOW_INTERVAL } from 'config/constants'
import Balance from 'components/Balance'
import Home2 from "./Home2";
import { rpcHost, candyMachineIdc3, network } from "./config";
import classes from "components/AppBar/Styles.module.css"
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";


const StyledBalance = styled(Text)`
  background: ${({ theme }) => theme.colors.gradients.blue};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`




const StyledLink = styled(NextLinkFromReactRouter)`
  width: 100%;
`

const PredictionCardHeader: React.FC<React.PropsWithChildren<{ preText: string; bnbWon: number }>> = ({
  preText,
  bnbWon,
}) => {
  const bnbBusdPrice = useBNBBusdPrice()
  const bnbWonInUsd = multiplyPriceByAmount(bnbBusdPrice, bnbWon)

  const localisedBnbUsdString = formatLocalisedCompactNumber(bnbWonInUsd)

  return (
    <Heading color="#280D5F" my="8px" scale="xl" bold>
      {preText}
      {localisedBnbUsdString}
    </Heading>
  )
}

const PredictionCardContent2 = () => {
  const { t } = useTranslation()
  const { observerRef, isIntersecting } = useIntersectionObserver()
  const [loadData, setLoadData] = useState(false)
  const bnbBusdPrice = useBNBBusdPrice()
  const cakePriceBusd = useCakeBusdPrice()
  const [checkStatus, setCheckStatus] = useState(false)
  const [checkCall, setCheckCall] = useState(false)
  // let onDismiss = () => null
  const wallet = useWallet();


  const { data } = useSWR(loadData ? ['prediction', 'tokenWon'] : null, getTotalWon, {
    refreshInterval: SLOW_INTERVAL,
  })

  const bnbWonInUsd = multiplyPriceByAmount(bnbBusdPrice, data?.totalWonBNB || 0)
  const cakeWonInUsd = multiplyPriceByAmount(cakePriceBusd, data?.totalWonCAKE || 0)

  const localisedBnbUsdString = formatLocalisedCompactNumber(bnbWonInUsd + cakeWonInUsd)
  const bnbWonText = t('$%wonInUsd% in BNB + CAKE won so far', { wonInUsd: localisedBnbUsdString })
  const [pretext, wonSoFar] = bnbWonText.split(localisedBnbUsdString)
  bnbWonInUsd=1000000000000000000

  useEffect(() => {
    if (isIntersecting) {
      setLoadData(true)
    }
  }, [isIntersecting])

  const handleCheckCall = () => {
    if (checkStatus === false){

      setCheckCall(true)
    }

  };

 /*
  const handleCheckCall = useEffect(() => {
    if (checkStatus === false){

      setCheckCall(true)
    }

  }, [checkStatus]);
*/



  const [onNotChecked, onDismiss] = useModal(
    <Modal
      onDismiss={onDismiss}
      id="disclaimer-limit-order"

    >

    <Flex pb="24px" flexDirection="column" textAlign="center">
      <Box mb={['0', '20px']} mr={[0, '20px']} style={{ float: 'left', margin: '0 20px 20px 0' }}>
        <img src="https://card-keys.unlimitedcope.com/2/img" width={200} height={100} responsive style={{ display: 'block', margin: 'auto' }} />
      </Box>
      <div style={{ clear: 'both', textAlign: 'center' }}>
        <Text bold textTransform="uppercase" fontSize="12px" color="white" mb="4px" textAlign="center" justifyContent="center"
          css={{ wordBreak: 'break-word' }}>
          {t('Are you sure you want to purchase a Level 3 COPE Card Key?')}
        </Text>
        <Text color="white" mb="24px" textAlign="center" justifyContent="center">
          {t('This action will burn 1250 COPE and spend 0.013 sol from your account. Note sometimes due to heavy congestion on the Solana network you may not see the popup appearing notifying you of your minted COPE Card Key in your wallet, please check your wallet.')}
        </Text>
      </div>
      <Home2 candyMachineId={candyMachineIdc3} closeModal={() => onDismiss()} />
    </Flex>






    </Modal>
  )



  useEffect(() => {



    if (checkCall) {
      onNotChecked()
      setCheckCall(false)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [checkCall])









  return (
    <>
      <Flex flexDirection="column" mt="48px">
        <Text color="#280D5F" bold fontSize="16px">
          {t('Level 3')}
        </Text>
        {bnbWonInUsd ? (
          <StyledBalance
            fontSize="40px"
            bold
            mb="20px"
          > 1250 COPE   </StyledBalance>
        ) : (
          <>
            <Skeleton width={230} height={40} my="8px" />
            <div ref={observerRef} />
          </>
        )}





          <ul>

          <Text color="black" mb="30px" bold fontSize="16px"><li> Unlock the gateway to thought-provoking discussions with renowned Trader AIs with COPE Chat   </li></Text>







        <Flex alignItems="center" justifyContent="center">
        {wallet.publicKey ? (

          <Button onClick={() => handleCheckCall()}  className={classes['wallet-adapter-new-button-spectator']}>  <Text bold color="invertedContrast">Purchase Card Key </Text> <ArrowForwardIcon ml="4px" color="invertedContrast" />  </Button>


        ) : (

          <WalletMultiButton className={classes['wallet-adapter-new-button-spectator']}>  <Text bold color="invertedContrast">Purchase Card Key </Text> <ArrowForwardIcon ml="4px" color="invertedContrast" />  </WalletMultiButton>



        )}




        </Flex>
        </ul>
      </Flex>

    </>
  )
}

export default PredictionCardContent2
